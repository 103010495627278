<template>
  <div class="upload-image-wr">
    <div class="upload-image" :class="{ 'upload-image--error': error, 'upload-image--valid': valid }" @dragover.prevent @drop="onDrop">
      <label v-if="!image" class="upload-image__label">
        <span class="upload-image__icon">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <use xlink:href="@/assets/images/sprites.svg#icon-download"></use>
          </svg>
        </span>
        <span class="upload-image__title" v-html="text"></span>
        <input type="file" name="image" @change="onChange">
      </label>
      <template v-else>
        <div  class="upload-image__image" @click="removeFile">
          <img :src="image" alt=""/>
        </div>
        <div class="upload-image__note upload-image__note--mobile" :class="{ 'upload-image__note--error': error }" v-html="text"></div>
      </template>    
      <a v-if="error" class="upload-image__status" href="#" @click.prevent="removeFile" title="Удалить">Удалить
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="8" cy="8" r="7.75" fill="red" stroke="#fff" stroke-width=".5"/>
          <path d="M11 11 5 5m6 0-6 6" stroke="#fff" stroke-linecap="round"/>
        </svg>
      </a>
      <div v-if="valid && image" class="upload-image__status">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="8" cy="8" r="7.75" fill="#17A400" stroke="#fff" stroke-width=".5"/>
          <path d="M5.44 8.617a1.084 1.084 0 0 0-.265.52l-.167 1.187c-.062.43.238.73.669.668l1.187-.167c.167-.026.405-.14.52-.264l3.113-3.113c.537-.537.792-1.161 0-1.953-.783-.783-1.407-.528-1.944.009L5.44 8.617Z" stroke="#fff" stroke-width=".5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.105 5.953a2.802 2.802 0 0 0 1.953 1.952" stroke="#fff" stroke-width=".5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <div class="upload-image__note upload-image__note--desktop" :class="{ 'upload-image__note--error': error }" v-html="text"></div>
    <div v-if="error_message" class="upload-image__error">{{ error_message }}</div>
  </div>
</template>

<script>
export default {
  name: 'UploadImage',
  data() {
    return {
      image: '',
      error: '',
      error_message: '',
      errors: {
        type: 'Неверный формат изображения',
        size: 'Размер изображения не должен привышать 3Mb',
        reload: 'Перезагрузите, пожалуйста изображение'
      },      
    };
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    params: {
      type: Object
    },
    status: {
      type: Object
    },
  },
  watch: {
    status: {
      handler(val) {
        if (val.error) {
          this.error = val.error;
          this.error_message = val.error_message;
        }
        if (val.valid) {
          this.error = '';          
          this.valid = val.valid;
        }
      },
      deep: true
    }
  },
  computed: {
    valid() {
      return this.status.valid ? true : false;
    }
  },
  methods: {
    onDrop(e) {
      e.stopPropagation();
      e.preventDefault();

      this.image = '';
      this.error = '';
      this.error_message = '';
      this.$emit('onClear', this.params);

      const files = e.dataTransfer.files;
      this.createFile(files[0]);
    },
    onChange(e) {
      const files = e.target.files;
      this.createFile(files[0]);
    },
    createFile(file) {
      if (!file.type.match('image/jpeg|image/png')) {
        this.error = this.errors.type;
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        this.image = e.target.result;
        this.error = '';
      };
      reader.readAsDataURL(file);

      const params = this.params;
      params.file = file;          
      this.$emit('changed', params);
    },
    removeFile() {
      if (this.valid || !!this.error) {
        this.image = '';
        this.error = '';
        this.error_message = '';        
        this.$emit('onClear', this.params);
      }
    }
  }
};
</script>